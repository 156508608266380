import { add, sub } from 'date-fns';
import { includes } from 'ramda';
import { DEFAULT_STORYBLOCK_SUFFIX } from 'my-phr/const';
import { UCUM_TIME_UNITS } from 'my-phr/components/result/const';
import { replaceAll } from 'utils';
import { MANUAL_INPUT_CATEGORY } from './const';
import manualInputsRegistry from './manualInputsRegistry';

export function getManualInput(type, props) {
  const Component = manualInputsRegistry.resolve(type);
  return Component ? <Component {...props} /> : <div></div>;
}

export function checkIfDateAndSet(date) {
  return date ? new Date(date) : null;
}

export function removeDefaultStoryBlockSuffix(type) {
  if (!type) return;

  if (includes?.(DEFAULT_STORYBLOCK_SUFFIX, type)) {
    return replaceAll(type, DEFAULT_STORYBLOCK_SUFFIX, '');
  }
  return type;
}

export function getLibraryAttachmentsIds(data) {
  return data?.attachments?.libraryAssets?.map(({ id }) => id);
}

export function parseNoteData(data) {
  return {
    notes: data?.notes,
    entryTags: data?.tags,
    files: data?.attachments?.attachments,
    libraryAttachments: getLibraryAttachmentsIds(data),
  };
}

export const minAllowedDate = sub(new Date(), { years: 100 });
export const maxAllowedDate = add(new Date(), { years: 25 });

function getDates(data) {
  return {
    effectiveTs: checkIfDateAndSet(data?.startDate ?? data?.date),
    discontinueTs: checkIfDateAndSet(data?.endDate ?? null),
  };
}

function getUnitAndValues(data) {
  let result;
  try {
    result = data?.totalSeconds
      ? { value: data.totalSeconds }
      : JSON.parse(data?.value);
  } catch {}
  return {
    value:
      result?.value !== null && result?.value !== undefined
        ? result?.value
        : data?.value,
    rangeValue:
      result?.rangeValue !== null && result?.rangeValue !== undefined
        ? result?.rangeValue
        : data?.rangeValue,
  };
}

export function parseAllergyData(data) {
  return {
    files: data?.attachments?.attachments,
    category: data?.category,
    storyblockId: data?.name?.suggestion?.id ?? null,
    name: data?.name?.value,
    severity: data?.severity,
    ...getDates(data),
    reactions: data?.reactions,
    additionalInfo: data?.notes,
    libraryAttachments: getLibraryAttachmentsIds(data),
    entryTags: data?.tags,
  };
}

export function parseVaccineData(data) {
  return {
    files: data?.attachments?.attachments,
    storyblockId: data?.name?.suggestion?.id ?? null,
    name: data?.name?.value,
    ...getDates(data),
    additionalInfo: data?.notes,
    libraryAttachments: getLibraryAttachmentsIds(data),
    entryTags: data?.tags,
  };
}

export function parseConditionData(data) {
  return {
    files: data?.attachments?.attachments,
    storyblockId: data?.name?.suggestion?.id ?? null,
    name: data?.name?.value,
    ...getDates(data),
    additionalInfo: data?.notes,
    libraryAttachments: getLibraryAttachmentsIds(data),
    entryTags: data?.tags,
  };
}

export function parseMedicationData(data) {
  return {
    files: data?.attachments?.attachments,
    strength: data?.strength,
    storyblockId: data?.name?.suggestion?.id ?? null,
    name: data?.name?.value,
    ...getDates(data),
    dosageInfo: data?.dosageInfo,
    additionalInfo: data?.notes,
    libraryAttachments: getLibraryAttachmentsIds(data),
    entryTags: data?.tags,
  };
}

export function parseProcedureData(data) {
  return {
    files: data?.attachments?.attachments,
    storyblockId: data?.name?.suggestion?.id ?? null,
    name: data?.name?.value,
    ...getDates(data),
    additionalInfo: data?.notes,
    libraryAttachments: getLibraryAttachmentsIds(data),
    entryTags: data?.tags,
  };
}

export function parseObservationData(data) {
  return {
    files: data?.attachments?.attachments,
    storyblockId:
      data?.name?.suggestion?.id ?? JSON.parse(data?.entry)?.id ?? null,
    name: data?.name?.value ?? JSON.parse(data?.entry)?.name,
    ...getDates(data),
    ...getUnitAndValues(data),
    unit: data?.totalSeconds ? UCUM_TIME_UNITS.SECOND : data?.unit,
    additionalInfo: data?.notes,
    category: data?.category,
    libraryAttachments: getLibraryAttachmentsIds(data),
    entryTags: data?.tags,
  };
}

export function parseObservationPanelData(data) {
  return {
    files: data?.attachments?.attachments,
    storyblockId:
      data?.name?.suggestion?.id ?? JSON.parse(data?.entry)?.id ?? null,
    name: data?.name?.value ?? JSON.parse(data?.entry)?.name,
    ...getDates(data),
    additionalInfo: data?.notes,
    libraryAttachments: getLibraryAttachmentsIds(data),
    entryTags: data?.tags,
    observations: data?.observations.map((item) => {
      return {
        storyblockId: item.id,
        name: item?.name,
        unit: item?.unit,
        additionalInfo: item?.notes,
        value:
          item?.totalSeconds ?? JSON.parse(item?.value)?.value ?? item?.value,
        rangeValue: item?.totalSeconds
          ? undefined
          : JSON.parse(item?.value)?.rangeValue,
        ...getDates(data),
      };
    }),
  };
}

export const parseMethods = {
  [MANUAL_INPUT_CATEGORY.ALLERGY]: parseAllergyData,
  [MANUAL_INPUT_CATEGORY.CONDITION]: parseConditionData,
  [MANUAL_INPUT_CATEGORY.MEDICATION]: parseMedicationData,
  [MANUAL_INPUT_CATEGORY.NOTE]: parseNoteData,
  [MANUAL_INPUT_CATEGORY.OBSERVATION]: parseObservationData,
  [MANUAL_INPUT_CATEGORY.MEASUREMENT]: parseObservationData,
  [MANUAL_INPUT_CATEGORY.OBSERVATION_PANEL]: parseObservationPanelData,
  [MANUAL_INPUT_CATEGORY.PROCEDURE]: parseProcedureData,
  [MANUAL_INPUT_CATEGORY.VACCINE]: parseVaccineData,
};
