import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { X } from 'components/icons';

import { usePublicResource } from 'hooks';
import { Button } from '../forms';

const KEY = 'smd-disclaimer-visible';

export default function Disclaimer() {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const { t } = useTranslation();

  const { data, isFetched } = usePublicResource('/stories.json');

  useEffect(() => {
    const shouldRender = sessionStorage.getItem(KEY) === null;
    if (shouldRender) {
      setIsVisible(true);
    }
  }, []);

  function hideDisclaimer() {
    setIsVisible(false);
    sessionStorage?.setItem(KEY, String(false));
  }

  const disclaimer = data?.disclaimer?.value ?? t('common.disclaimer');

  if (!isFetched || !isVisible) return null;

  return (
    <div className="fixed bottom-0 z-50 flex w-full items-start justify-between bg-smd-gray-darker px-2 py-3.5 text-white md:items-center md:px-4">
      <span className="px-4 text-smd-sm md:px-2">{disclaimer}</span>
      <Button
        className="hover:bg-smd-gray-dark"
        color="ghost"
        size="none"
        onClick={hideDisclaimer}
      >
        <X className="h-6 w-6 shrink-0 text-white" />
      </Button>
    </div>
  );
}
