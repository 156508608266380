import { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useClick, useHover } from '@szhsin/react-menu';
import { PHR_ROUTES } from 'my-phr/const';
import { viewerPath } from 'journals/viewer/const';

const HOOVER_CLOSE_DELAY = 300;

const HOOVER_OPEN_DELAY = HOOVER_CLOSE_DELAY - 20; //open little before close to prevent blinking when moving fast from menu to menu
export const constantas = { HOOVER_CLOSE_DELAY, HOOVER_OPEN_DELAY };
export function useJourneysHover() {
  const [isJourneyOpen, setJourneyOpen] = useState(false);
  const {
    anchorProps: hoverJourneyAnchorProps,
    hoverProps: hoverJourneyProps,
  } = useHover(isJourneyOpen, setJourneyOpen, {
    openDelay: HOOVER_OPEN_DELAY,
    closeDelay: HOOVER_CLOSE_DELAY,
  });
  const anchorProps = useClick(isJourneyOpen, setJourneyOpen);

  return {
    isJourneyOpen,
    setJourneyOpen,
    anchorProps,
    hoverJourneyAnchorProps,
    hoverJourneyProps,
  };
}

export function useDelayedTaskWithCommonReset(
  onClose,
  delay = HOOVER_CLOSE_DELAY
) {
  const [timers, setCloseTimers] = useState({});
  function resetTimer() {
    Object.keys(timers)?.map(clearTimeout);
    setCloseTimers({});
  }

  const delayTask = () => {
    const timer = setTimeout(() => {
      onClose();
    }, delay);
    setCloseTimers((x) => {
      x[timer] = true;
      return x;
    });
  };
  return { delayTask, resetTimer };
}

export function useMenuVisibilityState() {
  const showMenu = !useRouteMatch([
    `/${viewerPath.EMBED}`,
    PHR_ROUTES.SHARED_DASHBOARD,
    PHR_ROUTES.SHARED_DETAILS,
    PHR_ROUTES.SETUP_PROFILE,
    PHR_ROUTES.SETUP_PAYMENT_METHOD,
    PHR_ROUTES.SETUP_REDEEM,
    PHR_ROUTES.SETUP_REDEEM_SUCCESS,
    PHR_ROUTES.SETUP_PAYMENT,
  ]);
  return showMenu;
}
