import { createContext, useContext, useCallback, useEffect } from 'react';
import { useLocalStorage } from 'react-use';
import { add, set } from 'date-fns';
import { useAuth } from 'Auth';
import features from 'features';
import {
  useUserSourcesFilters,
  useCategories,
  useDateRanges,
  useSourcesFilters,
  useConnectedSources,
} from 'my-phr/hooks';

const FiltersProviderStateContext = createContext(null);
const FiltersProviderUpdaterContext = createContext(null);

export const SOURCE_TYPES = {
  EMR: 'EMR',
  MANUAL: 'MANUAL',
  OCR: 'OCR',
  WEARABLE: 'WEARABLE',
};

export function getEnabledPlatformSourceTypes(isAdmin) {
  return [
    SOURCE_TYPES.MANUAL,
    features.PHR.PHR_WEARABLES_ENABLED && SOURCE_TYPES.WEARABLE,
    features.PHR.PHR_EMRS_ENABLED && SOURCE_TYPES.EMR,
    (features.PHR.OCR_ENABLED || isAdmin) && SOURCE_TYPES.OCR,
  ].filter(Boolean);
}

export const initialStateFiltersProvider = {
  sources: [],
  categories: [],
  tags: [],
  timelineDateRangeFilter: 'all',
  searchTerm: '',
  userSourceBusinessIds: [],
};

const setZeroTimeDate = (date) =>
  set(date, {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });

const getDateWithOffset = (offset) => setZeroTimeDate(add(new Date(), offset));

const key = 'timeline-filters';

function FiltersProvider({ children }) {
  const { authenticated } = useAuth();

  const [filters, setFilters, removeFilters] = useLocalStorage(
    key,
    initialStateFiltersProvider
  );

  const { connectedSources: userSources } = useConnectedSources({
    select: (sources) => {
      return sources?.map(({ id, source }) => ({
        value: id,
        name: source.name,
        type: source.type,
      }));
    },
  });

  const categoriesFilters = useCategories(filters?.categories);

  const sourcesFilters = useSourcesFilters(filters?.sources);

  const businessIds = useUserSourcesFilters(
    filters,
    sourcesFilters,
    userSources
  );

  const dateRange = useDateRanges(filters?.timelineDateRangeFilter);

  useEffect(() => {
    if (!authenticated) {
      removeFilters();
    }
  }, [authenticated, removeFilters]);

  const setCategories = (value) => {
    if (
      filters?.categories.length > 0 &&
      value &&
      filters?.categories.includes(value[0])
    ) {
      const newSelected = filters?.categories.filter((el) =>
        value.every((v) => v !== el)
      );
      setFilters(() => ({ ...filters, categories: newSelected }));
    } else {
      setFilters(() => ({
        ...filters,
        categories: [...filters?.categories, ...value],
      }));
    }
  };

  const setTags = (tag) => {
    if (!tag || !tag?.length) return;
    const isSelected = tag && filters?.tags?.includes?.(tag);
    setFilters(() => {
      return {
        ...filters,
        tags: isSelected
          ? filters.tags.filter((value) => value !== tag)
          : filters?.tags
          ? [...filters?.tags, tag]
          : [tag],
      };
    });
  };

  const setSources = (value, selected) => {
    const businessIdsSet = new Set(
      filters.userSourceBusinessIds.filter(Boolean)
    );

    if (!selected) {
      businessIds[value]?.forEach(({ value }) => businessIdsSet.add(value));
      setFilters(() => ({
        ...filters,
        sources: [...filters.sources, value],
        userSourceBusinessIds: Array.from(businessIdsSet),
      }));
      return;
    }

    const isRemovingSource =
      value && (filters.sources.includes(value) || selected);

    let sources = [...filters.sources];
    if (isRemovingSource) {
      sources = filters?.sources.filter((source) => source !== value);
    } else {
      sources.push(value);
    }

    businessIds[value]?.forEach(({ value }) =>
      isRemovingSource
        ? businessIdsSet.delete(value)
        : businessIdsSet.add(value)
    );

    setFilters({
      ...filters,
      sources,
      userSourceBusinessIds: Array.from(businessIdsSet),
    });
  };

  const setTimelineDateRangeFilter = ({ value }) => {
    let dateRangeFilter = {
      startDateFilter: undefined,
      endDateFilter: undefined,
    };
    if (value !== 'all') {
      dateRangeFilter = {
        startDateFilter: getDateWithOffset({ [value]: -1 }),
        endDateFilter: new Date(),
      };
    }
    setFilters(() => ({
      ...filters,
      ...dateRangeFilter,
      timelineDateRangeFilter: value,
    }));
  };

  function setBusinessIds(id, sourceType) {
    setFilters(() => {
      const newBusinessIds = Object.keys(businessIds).reduce((acc, type) => {
        const ids = businessIds[type]
          .filter(({ selected }) => selected)
          .map(({ value }) => value);
        return acc.concat(ids);
      }, []);
      const newBusinessIdsSet = new Set(newBusinessIds);

      if (newBusinessIdsSet.has(id)) {
        newBusinessIdsSet.delete(id);
      } else {
        newBusinessIdsSet.add(id);
      }

      let newSources = [...filters.sources];
      const areAllSelected = businessIds[sourceType].every(({ value }) =>
        newBusinessIdsSet.has(value)
      );

      if (areAllSelected && !newSources.includes(sourceType)) {
        // add source type
        newSources.push(sourceType);
      }

      const areSomeUnselected = businessIds[sourceType].some(
        ({ value }) => !newBusinessIdsSet.has(value)
      );
      const shouldRemoveSourceType =
        newSources.includes(sourceType) && areSomeUnselected;

      if (shouldRemoveSourceType) {
        // remove source type
        newSources = newSources.filter((source) => source !== sourceType);
      }

      return {
        ...filters,
        userSourceBusinessIds: Array.from(newBusinessIdsSet),
        sources: newSources,
      };
    });
  }

  return (
    <FiltersProviderStateContext.Provider
      value={{
        filters,
        categoriesFilters,
        sourcesFilters,
        dateRange,
        businessIds,
      }}
    >
      <FiltersProviderUpdaterContext.Provider
        value={{
          setFilters,
          setCategories,
          setSources,
          setTimelineDateRangeFilter,
          setTags,
          setBusinessIds,
        }}
      >
        {children}
      </FiltersProviderUpdaterContext.Provider>
    </FiltersProviderStateContext.Provider>
  );
}

function useFiltersProviderState() {
  const contextState = useContext(FiltersProviderStateContext);
  if (typeof contextState === 'undefined') {
    throw new Error(
      'useFiltersProviderState must be used within a FiltersProvider'
    );
  }
  return contextState;
}

function useFiltersProviderUpdater() {
  const contextUpdater = useContext(FiltersProviderUpdaterContext);
  if (typeof contextUpdater === 'undefined') {
    throw new Error(
      'useFiltersProviderUpdater must be used within a FiltersProvider'
    );
  }
  return useCallback(contextUpdater, [contextUpdater]);
}

function useFiltersProvider() {
  return [useFiltersProviderState() || {}, useFiltersProviderUpdater() || {}];
}

export { FiltersProvider, useFiltersProvider };
